<template>
  <div
    class="footer"
    :class="{'footer_full': full}"
  >
    <div class="footer__container container">
      <div v-if="nav.length" class="footer__top">
        <template
          v-for="(group, i) in nav"
        >
          <div
            class="footer__nav footer__top-item"
            :key="i"
          >
            <div class="footer-group footer-group_collapsed">
              <div class="footer-group__title" @click="toggleFooterItem">
                {{ group.name }}
                <Icon class="footer-group__title-icon" name="down"></Icon>
              </div>
              <div class="footer-group__items">
                <div
                  class="footer-group__item"
                  v-for="(item, j) in group.items"
                  :key="i + '_' + j"
                >
                  <Link
                    :href="item.href"
                    @click="item.action ? item.action($event) : null"
                    :text="item.name"
                    variant="inherit"
                    :target="item.target"
                  ></Link>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__divider d-md-none" :key="i + 'd'"></div>
        </template>
        <template v-if="logo">
          <div class="footer__divider footer__divider_top d-lg-none"></div>
          <div class="footer__top-item footer__logos">
            <div class="footer__logos-items">
              <a :href="logoUrl" class="footer__logos-item">
                <img :src="logo" alt="">
              </a>
            </div>
          </div>
        </template>
      </div>
      <div :class="{'footer__middle': full}">
        <div class="footer__items">
          <div class="footer__item">
            <div v-if="socials.length" class="footer__social">
              <a
                v-for="item in socials"
                :key="item.icon"
                class="footer__social-item"
                :href="item.link"
                target="_blank"
              >
                <Icon :name="item.icon"></Icon>
              </a>
            </div>
          </div>
          <div v-if="locales.length" class="footer__item">
            <LangSwitch
              class="footer__lang"
              :locales="locales"
              :locale="locale"
              :dropdownOptions="{
              triggerId: 'footerLangSwitch',
              placement: 'top-end',
            }"
              :isFlags="isFlags"
              @change="changeLang"
            >
              <template v-slot:default="{ language }">
                <Button
                  id="footerLangSwitch"
                  variant="ghost"
                  view="light"
                  size="sm"
                  :text="language.text"
                  iconLeft="global"
                  class="footer__lang-button"
                >
                </Button>
              </template>
            </LangSwitch>
          </div>
          <div class="footer__item footer__copyright">
            <span v-html="copyright"></span>
            <template
              v-for="(itemContact, i) in contacts"
            >
              &nbsp;|&nbsp;
              <template v-if="itemContact.caption">{{ itemContact.caption }}</template>
              <Link
                :key="i"
                :text="itemContact.text"
                :href="itemContact.link"
                variant="inherit"
                size="sm"
                :target="itemContact.blank ? '_blank' : ''"
              ></Link>
            </template>
            <template
              v-for="(link, i) in links"
            >
              &nbsp;|&nbsp;
              <Link
                :key="i"
                class="color-white"
                :text="link.text"
                :href="link.link"
                target="_blank"
                variant="inherit"
                size="sm"
              ></Link>
            </template>
          </div>
        </div>
      </div>
      <div
        v-if="partners.length"
        class="footer__partners"
        :class="`footer__partners--${partnersLogosColor}`">
        <div
          v-for="(item, index) in partners"
          :key="index"
          class="footer__partners-item"
        >
          <img :src="require(`@/assets/img/${partnersLogosFolder}${item.img}.${item.extension || 'svg'}`)" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { setCulture } from '@/utils';
import Icon from '@rr-component-library/icon/src/main';
import LangSwitch from '@/components/LangSwitch/LangSwitch.vue';

export default {
  name: 'Footer',
  components: {
    Icon,
    LangSwitch,
  },
  props: {
    copyright: {
      type: String,
      default: '',
    },
    socials: {
      type: Array,
      default: () => [],
    },
    contacts: {
      type: Array,
      default: () => [],
    },
    links: {
      type: Array,
      default: () => [],
    },
    partners: {
      type: Array,
      default: () => [],
    },
    partnersLogosColor: {
      type: String,
      default: 'colored',
      validator(val) {
        return ['colored', 'white'].includes(val);
      },
    },
    locales: {
      type: Array,
      default: () => [],
    },
    locale: {
      type: String,
      default: 'ru',
    },
    full: {
      type: Boolean,
      default: false,
    },
    nav: {
      type: Array,
      default: () => [],
    },
    logo: {
      type: String,
      default: '',
    },
    logoUrl: {
      type: String,
      default: '/',
    },
    isFlags: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('settings', ['themeImages', 'themeUrls']),
    partnersLogosFolder() {
      const folders = {
        white: 'partners-white-logos/',
        colored: '',
      };

      return folders[this.partnersLogosColor];
    },
  },
  methods: {
    toggleFooterItem(e) {
      e.target.parentNode.classList.toggle('footer-group_collapsed');
    },
    changeLang(lang) {
      let origin = this.themeUrls.Events;

      if (window.location.hostname === 'localhost') {
        origin = 'https://localhost:44300/';
      }

      setCulture(lang, origin);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "./Footer";
</style>
