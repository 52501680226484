<template>
  <FooterNew
    :copyright="copyright"
    :contacts="contacts"
    :socials="socials"
    :partners="partners"
    :locales="locales"
    :isFlags="true"
    :locale="$lang"
    :logoUrl="logoUrl"
    partnersLogosColor="white"
    full
    :nav="nav"
    :logo="themeImages['logo-inverse']"
  ></FooterNew>
</template>

<script>
import { mapGetters } from 'vuex';
import FooterNew from '@/components/FooterNew/Footer.vue';
import ru from '../locales/ru.json';
import en from '../locales/en.json';
import uz from '../locales/uz.json';

let themeUrls = null;

export default {
  name: 'SamarkandFooter',
  i18n: {
    messages: {
      ru,
      en,
      uz,
    },
  },
  components: {
    FooterNew,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      socials: [
        {
          icon: 'strava',
          link: 'https://www.strava.com/clubs/553880',
        },
        {
          icon: 'facebook',
          link: 'https://www.facebook.com/samarkandmarathon/',
        },
        {
          icon: 'instagram',
          link: 'https://www.instagram.com/samarkandmarathon/',
        },
        {
          icon: 'telegram',
          link: 'https://t.me/samarkandmarathon',
        },
        {
          icon: 'youtube',
          link: 'https://www.youtube.com/channel/UCVpX_kctpX7Bmts1EzZ4tmg?sub_confirmation=1',
        },
      ],
      partners: [
        {
          img: 'visa',
          extension: 'png',
        },
        {
          img: 'mastercard',
          extension: 'png',
        },
        {
          img: 'maestro',
          extension: 'png',
        },
        {
          img: 'humo',
          extension: 'png',
        },
        {
          img: 'uzcard',
          extension: 'png',
        },
        {
          img: 'mir',
          extension: 'png',
        },
      ],
      partnersLogosColor: 'white',
      locales: ['en', 'uz', 'ru'],
      nav: [
        // {
        //   name: this.$t('nav.about'),
        //   items: [
        //     {
        //       name: this.$t('nav.mission'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fmission',
        //     },
        //     {
        //       name: this.$t('nav.media_center'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fabout',
        //     },
        //     {
        //       name: this.$t('nav.partners'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fabout',
        //     },
        //     {
        //       name: this.$t('nav.contacts'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fcontact',
        //     },
        //   ],
        // },
        {
          name: this.$t('nav.participants'),
          items: [
            {
              name: this.$t('nav.find_event'),
              href: '',
            },
            {
              name: this.$t('nav.results'),
              href: themeUrls.Results,
            },
            {
              name: this.$t('nav.participants_list'),
              href: '',
            },
            // {
            //   name: this.$t('nav.question_answer'),
            //   href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site1%2Finstruction',
            // },
          ],
        },
        // {
        //   name: this.$t('nav.inclusion'),
        //   items: [
        //     {
        //       name: this.$t('nav.lectures'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site1%2Flecture',
        //     },
        //     {
        //       name: this.$t('nav.master_classes'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site1%2Fmaster',
        //     },
        //   ],
        // },
        // {
        //   name: this.$t('nav.culture'),
        //   items: [
        //     {
        //       name: this.$t('nav.films'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site1%2Ffilm',
        //     },
        //     {
        //       name: this.$t('nav.online_concert'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fabout',
        //     },
        //   ],
        // },
      ],
      contacts: [
        {
          text: 'marathon@acdf.uz',
          link: 'mailto:marathon@acdf.uz',
        },
        {
          caption: 'telegram:',
          text: '@samarkandmarathon_support',
          link: 'https://t.me/samarkandmarathon_support',
          blank: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('settings', ['themeImages']),
    copyright() {
      return `© ${this.year}, ${this.$t('footer.copyright')}`;
    },
    logoUrl() {
      return window.location.hostname === 'localhost' ? '/' : 'https://events.samarkandmarathon.uz';
    },
  },
  beforeCreate() {
    themeUrls = this.$store.getters['settings/themeUrls'];
  },
};
</script>
